<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="500px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title> Pergunta </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-textarea
              v-model="form.question"
              solo
              flat
              placeholder="Digite uma pergunta..."
              background-color="grey lighten-4"
              no-resize
              hide-details="auto"
              rows="3"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="dialog = false" text class="mr-2"> Cancelar </v-btn>
        <v-btn @click="handleSave()" color="primary"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      baseForm: {
        question: null,
        answer: null,
      },
      isInsert: null,

      form: {},
    };
  },

  created() {
    this.reset();
  },

  methods: {
    open(question) {
      this.reset();
      if (question) {
        this.setQuestion(question);
      }

      this.dialog = true;
    },

    setQuestion(question) {
      this.form = JSON.parse(JSON.stringify(question));
      this.isInsert = false;
    },

    reset() {
      this.isInsert = true;
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$emit("store", this.form);
      this.dialog = false;
    },

    update() {
      this.$emit("update", this.form);
      this.dialog = false;
    },
  },
};
</script>

<style></style>
