<template>
  <div>
    <v-row class="mx-2">
      <v-col cols="12" md="8" offset-md="2">
        <v-row class="mt-8">
          <v-col cols="4" class="pa-0">
            <app-text-field v-model="form.name" label="Nome do Prontuario" />
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col class="pa-0">
            <draggable
              v-model="form.questions"
              @start="drag = true"
              @end="drag = false"
              handle=".handle"
            >
              <template v-for="(question, index) in form.questions">
                <div :key="index">
                  <template v-if="question.type == 'question'">
                    <v-card class="mb-6">
                      <v-card-text class="d-flex justify-space-between">
                        <div>
                          <span style="font-size: 18px">
                            {{ question.question }}
                          </span>
                        </div>
                        <div>
                          <v-btn
                            @click="openQuestionForm(question, index)"
                            text
                            icon
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn text icon @click="removeQuestion(index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn icon text>
                            <app-icon class="handle"> drag_indicator </app-icon>
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </template>
                  <template v-if="question.type == 'heading'">
                    <div
                      class="d-flex justify-space-between pr-4"
                      style="width: 100%"
                    >
                      <div>
                        <h3 class="mt-6 mb-2">{{ question.heading }}</h3>
                      </div>
                      <div>
                        <v-btn
                          @click="openHeadingForm(question, index)"
                          text
                          icon
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn text icon @click="removeQuestion(index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn icon text>
                          <app-icon class="handle"> drag_indicator </app-icon>
                        </v-btn>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </draggable>
            <div class="d-flex row">
              <v-btn
                class="mt-4 col-12 mr-4 col-md-3"
                @click="openQuestionForm()"
                color="primary"
                outlined
              >
                Adicionar Pergunta
              </v-btn>
              <v-btn
                class="mt-4 col-12 col-md-3"
                @click="openHeadingForm()"
                color="primary"
                outlined
              >
                Adicionar SubTítulo
              </v-btn>
              <v-btn
                class="mt-4 col-12 ml-auto col-md-2"
                @click="handleSave()"
                color="primary"
              >
                Salvar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ProfessionalRecordQuestionForm
      ref="ProfessionalRecordQuestionForm"
      @update="updateQuestion($event)"
      @store="addQuestion($event)"
    />
    <ProfessionalRecordHeadingForm
      @update="updateHeading($event)"
      @store="addHeading($event)"
      ref="ProfessionalRecordHeadingForm"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ProfessionalRecordQuestionForm from "@/components/professional-record/forms/ProfessionalRecordQuestionForm";
import ProfessionalRecordHeadingForm from "@/components/professional-record/forms/ProfessionalRecordHeadingForm";

export default {
  components: {
    draggable,
    ProfessionalRecordQuestionForm,
    ProfessionalRecordHeadingForm,
  },
  data() {
    return {
      indexHeading: null,
      editIndex: null,
      form: {},
      baseForm: {
        name: null,
        questions: [],
      },
    };
  },
  computed: {
    isInsert() {
      return !this.form.id;
    },
  },

  created() {
    this.open(this.$route.params.id);
  },

  methods: {
    open(id = null) {
      this.reset();
      if (id > 0) {
        this.showProfessionalRecord(id);
      }
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    async showProfessionalRecord(id) {
      this.$loading.start();
      await this.$http
        .show("professional-record/professional-record", id)
        .then((response) => {
          this.form = response.professional_record;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    openQuestionForm(question, index) {
      this.editIndex = index;
      this.$refs.ProfessionalRecordQuestionForm.open(question, index);
    },

    updateQuestion(data) {
      this.$set(this.form.questions, this.editIndex, data);
    },

    handleSave() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$loading.start();
      this.$http
        .store("professional-record/professional-record", this.form)
        .then((response) => {
          this.processSaved(response.professional_record);
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    update() {
      this.$loading.start();
      this.$http
        .update(
          "professional-record/professional-record",
          this.form.id,
          this.form
        )
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved(professional_record) {
      this.$loading.finish();
      this.$emit("store", professional_record);
      this.$router.push("/configuracoes/prontuario");
      // this.dialog = false;
    },
    removeQuestion(index) {
      this.$delete(this.form.questions, index);
    },
    async addQuestion(data) {
      await this.form.questions.push({
        question: data.question,
        answer: data.answer,
        type: "question",
      });
    },
    async addHeading(data) {
      await this.form.questions.push({
        type: "heading",
        heading: data.heading,
      });
    },

    openHeadingForm(heading, index) {
      this.indexHeading = index;
      console.log(heading);

      this.$refs.ProfessionalRecordHeadingForm.open(heading, index);
    },
    updateHeading(data) {
      this.$set(this.form.questions, this.indexHeading, data);
    },
  },
};
</script>

<style scoped></style>
