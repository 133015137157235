<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <app-text-field v-model="form.heading" name="name" />
      </v-card-title>
      <v-card-actions id="form-card-footer">
        <v-btn @click="dialog = false" text> Cancelar </v-btn>
        <v-btn @click="handleSave()" class="mr-2" color="primary">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      form: {},
      baseForm: {
        heading: null,
      },
      isInsert: null,
    };
  },
  created() {
    this.reset();
  },

  methods: {
    open(question) {
      this.reset();
      if (question) {
        this.setQuestion(question);
      }

      this.dialog = true;
    },

    setQuestion(question) {
      this.form = JSON.parse(JSON.stringify(question));
      this.isInsert = false;
    },

    reset() {
      this.isInsert = true;
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$emit("store", this.form);
      this.dialog = false;
    },

    update() {
      this.$emit("update", this.form);
      this.dialog = false;
    },
  },
};
</script>

<style></style>
